export default [
  'React',
  'Vue',
  'Angular',
  'RxJS',
  'Redux',
  'Gatsby',
  'Flutter',
  'React Native',
  'Swift',
  'Kotlin',
  'Java',
  'PHP',
  'NestJS',
  'Laravel',
  'GraphQL',
  '.NET',
  'SQL Server',
  'MySQL',
  'PostgreSQL',
  'DynamoDB',
  'MongoDB',
  'Node.JS',
  'AWS',
  'Azure',
  'Google Cloud',
  'Firebase',
  'Amplify',
  'Typescript'
];
