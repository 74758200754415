import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Hamburger from 'hamburger-react';
import tw, { styled } from 'twin.macro';
import Button from '../Button';
import Logo from '../Logo';

const StyledMenuItem = styled(AnchorLink)`
  ${tw`py-6 text-gray-400 text-center w-full font-bold hover:text-gray-600 text-2xl hover:bg-gray-300 hover:cursor-pointer`}
`;

const StyledMobileMenu = styled.div`
  ${tw`fixed md:hidden h-full w-4/5 bg-gray-800 top-0 right-0 z-30 text-white flex flex-col items-center justify-center`}
  max-width: 400px;
  transition: 0.4s right ease-out;
`;

const MobileMenu = ({ menuIsOpen, setMenuIsOpen }) => (
  <>
    <StyledMobileMenu style={{ ...(!menuIsOpen ? { right: '-400px' } : {}) }}>
      <StyledMenuItem href="#what-we-do" onClick={() => setMenuIsOpen(false)}>
        What We Do
      </StyledMenuItem>
      <StyledMenuItem href="#our-philosophy" onClick={() => setMenuIsOpen(false)}>Philosophy</StyledMenuItem>
      <StyledMenuItem href="#our-process" onClick={() => setMenuIsOpen(false)}>Process</StyledMenuItem>
      <StyledMenuItem href="#technology" onClick={() => setMenuIsOpen(false)}>Technology</StyledMenuItem>
      <div
        className={`block fixed md:hidden top-0 right-0 mt-1 mr-6 z-50 ${
          menuIsOpen ? 'opacity-1' : 'opacity-0'
        }`}
        style={{
          transition: '1s opacity ease-out'
        }}
      >
        <Hamburger toggled={menuIsOpen} toggle={setMenuIsOpen} size={28} color="#FFF" />
      </div>
    </StyledMobileMenu>
  </>
);

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <header className="sticky top-0 bg-white shadow">
        <div className="container flex justify-between items-center mx-auto py-4 px-8">
          <div className="flex items-center">
            <Logo />
          </div>
          <div className="flex mt-4 sm:mt-0 hidden md:flex">
            <AnchorLink className="px-4" href="#what-we-do">
              What We Do
            </AnchorLink>
            <AnchorLink className="px-4" href="#our-philosophy">
              Philosophy
            </AnchorLink>
            <AnchorLink className="px-4" href="#our-process">
              Process
            </AnchorLink>
            <AnchorLink className="px-4" href="#technology">
              Technology
            </AnchorLink>
          </div>
          <div className="hidden md:block">
            <Button
              className="text-sm"
              onClick={() => document.getElementById('get-in-touch').scrollIntoView()}
            >
              Get In Touch
            </Button>
          </div>
          <div className="fixed md:hidden top-0 right-0 mt-1 mr-6 z-50 md:hidden">
            <Hamburger toggled={menuIsOpen} toggle={setMenuIsOpen} size={28} color="#444" />
          </div>
        </div>
      </header>
      <MobileMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
    </>
  );
};

export default Header;
