import React from 'react';
import tw, { styled } from 'twin.macro';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import SvgCharts from '../svg/SvgCharts';
import NumberCircle from '../components/NumberCircle';
import technologyList from '../data/technology-list';

const ScrollAnchor = styled.div`
  ${tw`invisible`}
  margin-top: -40px;
  height: 40px;
`;

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl sm:text-5xl font-bold leading-none">
            Innovative solutions for complex problems.
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            We deliver cutting edge, cloud-based software solutions tailored to your business needs.
          </p>
          <p className="mt-8 md:mt-12">
            <Button
              size="lg"
              onClick={() => document.getElementById('what-we-do').scrollIntoView()}
            >
              Learn More
            </Button>
          </p>
        </div>
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img src="/assets/Hero.svg" alt="hero" />
        </div>
      </div>
    </section>
    <section id="what-we-do" className="py-20 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">What We Do</h2>
        <div className="flex flex-col sm:flex-row sm:mx-3 mt-8 sm:mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Custom Software</p>
              <p className="mt-4">
                We specialize in custom software development, and build world class iOS and Android
                apps, web apps and websites backed by highly scalable backends.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Cloud Computing</p>
              <p className="mt-4">
                Our in-house cloud certified solution architects specialize in highly scalable,
                performant, cloud-native architectures, cost optimization, and migration from
                on-prem to the cloud.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">UI/UX Design</p>
              <p className="mt-4">
                We leverage Figma to create beautiful, high fidelity prototypes. Our designers do much more than make the UI look great - the user
                experience is also critical.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <ScrollAnchor id="our-philosophy" />
    <section className="container mx-auto sm:my-12 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-4xl sm:text-5xl font-semibold">Testing is at our Core</h3>
      <p className="mt-8 text-xl font-light px-4 md:px-8 lg:w-2/3 mx-auto">
        Automated testing is at the core of our practice, and is included in every custom solution
        we build. <span className="font-semibold">Completely free of cost</span>. Automated testing
        provides a higher quality product, allows our developers to move faster, and ensures
        maintainability in the long run.
      </p>
      <p className="mt-8">
        <Button size="xl" onClick={() => document.getElementById('get-in-touch').scrollIntoView()}>
          Learn More
        </Button>
      </p>
    </section>
    <ScrollAnchor id="understanding-your-business" />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Understanding Your Business</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We work to get a solid understanding of your business domain and challenges, so that we
            can deliver the best solution possible.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Engineering Excellence</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We build with solid, proven principles such as Domain Driven Design, Service Oriented Architecture, Multi-tier Architecture, Microservices, Event
            Driven Architecture, and more!
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">We're Agile</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We utilize the Agile Methodology to manage changing requirements and deliver the right
            features, faster.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Continuous Integration & Delivery
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We leverage tools like AWS CodePipeline, Azure Pipeline, Circle CI and Bitrise to
            optimize our software delivery process and push new features out rapidly.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Secure by Design</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We follow industry best practices for network and application security, including
            standards and protocols such as OAuth, OpenID Connect, HTTPS and SAML.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <ScrollAnchor id="our-process" />
    <section className="py-8 sm:py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Our Process</h2>
        <div className="flex flex-col sm:flex-row mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={1} /> UI/UX Design
              </p>
              <p className="mt-4">
                All custom software solutions are completely wireframed on Figma before development
                begins.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={2} /> Data Modeling
              </p>
              <p className="mt-4">
                It's all about the data! Data is modeled based on the domain, and UML diagrams are
                created.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={3} /> Cloud Architecture
              </p>
              <p className="mt-4">
                The cloud architecture of the system is planned based on the requirements and
                diagrams are created.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={4} /> Product Backlog
              </p>
              <p className="mt-4">
                User Stories are added to the Jira backlog. Sprint planning begins.
              </p>
            </Card>
          </div>
        </div>
      </div>
      <div className="container mx-auto text-center">
        <div className="flex flex-col sm:flex-row sm:mx-3">
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={5} /> Development
              </p>
              <p className="mt-4">
                We get to work! At the end of each development sprint, we release features for
                feedback from the project owner.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={6} /> Initial Release
              </p>
              <p className="mt-4">When you are happy with the product, we release it!</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-4 sm:mb-8">
              <p className="font-semibold text-lg">
                <NumberCircle num={7} /> Support
              </p>
              <p className="mt-4">
                Software is never finished. We’ll be here to provide support for your application as
                needed.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <ScrollAnchor id="technology" />
    <section className="py-8 sm:py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Technology Agnostic</h2>
        <div className="px-4 lg:px-48 mt-6">
          <p className="text-xl">
            We're technology agnostic. Our rockstar engineers are passionate about learning and pick
            up new technologies quickly. This gives us the ability to choose the right technology
            for the job, instead of leaning towards one stack. Some technologies we've had the
            privilege of working with:
          </p>
        </div>
        <div className="flex flex-row flex-wrap mt-10 sm:mt-12 sm:mx-3">
          {technologyList.map(name => (
            <div className="flex-1 px-1 sm:px-2">
              <Card className="mb-3 sm:mb-8" paddingOverride="p-4 sm:p-12">
                <p className="font-semibold text-sm sm:text-xl">{name}</p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
    <ScrollAnchor id="get-in-touch" />
    <section className="container mx-auto my-8 sm:my-16 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-3xl sm:text-5xl px-2 font-semibold">Let's build something awesome.</h3>
      <p className="mt-10">
        <Button size="xl" className="m-2" onClick={() => window.open('mailto:cloudx.bz@gmail.com')}>
          E-mail
        </Button>
        <Button
          size="xl"
          className="m-1"
          onClick={() => window.open('https://drift.me/cloudxbz/meeting', '_blank')}
        >
          Schedule Meeting
        </Button>
      </p>
    </section>
    <script
      dangerouslySetInnerHTML={{
        __html: `
      "use strict";

!function() {
  var t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
    t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
    t.factory = function(e) {
      return function() {
        var n = Array.prototype.slice.call(arguments);
        return n.unshift(e), t.push(n), t;
      };
    }, t.methods.forEach(function(e) {
      t[e] = t.factory(e);
    }), t.load = function(t) {
      var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
      o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
      var i = document.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(o, i);
    };
  }
}();
drift.SNIPPET_VERSION = '0.3.1';
drift.load('ygsyywvce2bk');
      `
      }}
    />
  </Layout>
);
