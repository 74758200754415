import React from 'react';
import styled from '@emotion/styled';

const StyledLogo = styled.img`
  height: 23px;
`;

const Logo = ({ variant }) => {
  const finalVariant = variant || 'blue';

  return <StyledLogo src={`/assets/cloudx-logo-${finalVariant}.png`} alt="logo" />;
};

export default Logo;
