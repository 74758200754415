import React from 'react';
import Logo from '../Logo';

const Footer = () => (
  <footer className="container mx-auto p-4 sm:p-16 mt-8 mb-8 text-gray-300 bg-gray-800 rounded-lg">
    <div className="flex flex-col sm:flex-row py-8 sm:py-0 mx-3">
      <div className="flex-1 px-3">
        <Logo variant="white" />
        <p className="mt-5 text-gray-400">CloudX Technology Group, LLC</p>
        <p className="mt-2 text-gray-400">cloudx.bz@gmail.com</p>
      </div>
      <div className="flex-1 px-3 mt-10 sm:mt-0 text-center">
        <h2 className="text-lg font-semibold">Contact</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a
              href="https://drift.me/cloudxbz/meeting"
              className="text-gray-400 hover:text-gray-100"
              target="_blank"
              rel="noreferrer"
            >
              Schedule Meeting
            </a>
          </li>
          <li>
            <a href="mailto:cloudx.bz@gmail.com" className="text-gray-400 hover:text-gray-100">
              Open E-mail
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3 text-center mt-10 sm:mt-0">
        <h2 className="text-lg font-semibold">Social</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <span className="text-gray-400 hover:text-gray-100">Facebook</span>
          </li>
          <li>
            <span className="text-gray-400 hover:text-gray-100">GitHub</span>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
